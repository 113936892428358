import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    myData: {}
}

const myDataSlice = createSlice({
    name: 'myDataSlice',
    initialState,
    reducers: {
        setMyDataSlice: (state, action) => {
            state.myData = action.payload
        },
        getMyDataSlice: (state, action) => {
            state.myData = action.payload
        }
    }
})

export const {setMyDataSlice, getMyDataSlice} = myDataSlice.actions;
export default myDataSlice.reducer;
