import React from 'react'



import makeCarousel from 'react-reveal/makeCarousel';
import Slide from 'react-reveal/Slide';
import styled, { css } from 'styled-components';

// IMAGES SLIDERS
import puente1 from '../../images/fondo-slides/fondo-1.jpeg'
import puente2 from '../../images/fondo-slides/fondo-2.jpeg'
import puente3 from '../../images/fondo-slides/fondo-3.jpeg'

const width = '100%', height = '100vh';


/* STYLES PARA MOSTRAR/OCULTAL SLIDER ACTUAL */
const Container = styled.div`
  /* position: relative; */

  width: ${width};
  min-height: ${height};
  text-align: center;
  /* margin-bottom: 520px; */
  background-color: #212529;
  color: white;
  
  
`;

const Children = styled.div`
  width: ${width};
  position: relative;
  min-height: ${height};
  
`;

/* STYLES PARA LAS FLECHAS DERECHA E IZQUIERDA */
const Arrow = styled.div`
  text-shadow: 1px 1px 1px #fff;
  z-index: 100;
  line-height: ${height};
  text-align: center;
  position: absolute;
  top: 0;
  width: 10%;
  font-size: 3em;
  cursor: pointer;
  user-select: none;
  

  /* color: #ed5f30; */
  
/* background-color: yellow; */
  ${props => props.right ? css`left: 90%;` : css`left: 0%;`}
`;

/* STYLES PARA VER LOS SLIDERS ACTUALES */
const Dot = styled.span`
  font-size: 2.5em;
  cursor: pointer;
  text-shadow: 1px 1px 1px #fff;
  user-select: none; 
`;

const Dots = styled.span`
  text-align: center;
  width: ${width};
  z-index: 100;
`;

const Texto = styled.span`
  color: ${props=> props.color};
  font-size: ${props=> props.font};
  text-align: center;
  padding: 30px;
  display: block;
`;





//API PARA TRAER EL ARRAY DE IMAGENES
const images = [
  {
    url: `${puente1}`,
    title: "Imagen numero 1",
    contenido: 'Parrafo contenido de ejemplo 1'
  },
  {
    url: `${puente2}`,
    title: "Imagen numero 2",
    contenido: 'Parrafo contenido de ejemplo 2'
  },
  {
    url: `${puente3}`,
    title: "Imagen numero 3",
    contenido: 'Parrafo contenido de ejemplo 3'
  },
];



const CarouselUI = ({ position, total, handleClick, children }) => (

    <Container>
      <Children>
        {children}
        <Arrow onClick={handleClick} data-position={position - 1}>{'<'}</Arrow>
        <Arrow right onClick={handleClick} data-position={position + 1}>{'>'}</Arrow>
      </Children>
      <Dots>
        {Array(...Array(total)).map((val, index) =>
          <Dot key={index} onClick={handleClick} data-position={index}>
            {index === position ? '● ' : '○ '}
          </Dot>
        )}
      </Dots>
    </Container>
);
const Carousel = makeCarousel(CarouselUI);



export const CarrucerConFlechas = () => {
  return (
    <Carousel defaultWait={5000} >
      {
        images.map(({ url, title, contenido }) => (
          <Slide
            key={url}
            right>
            <div
              style={{
                height: '100vh',
                backgroundImage: "url(" + `"${url}"` + ")",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
              }} >

              <Texto color="white" font="30px">{title}</Texto>
              <Texto color="white" font="18px">{contenido}</Texto>
            </div>
          </Slide>
        ))
      }
    </Carousel>
  )
}
