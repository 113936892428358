import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { logOut } from '../../../Api/peticiones_server'
import iconogogym from '../../../images/gogym/icono.png'
import iconosisboa from '../../../images/sisboa/icono.png'
import { HeaderElement, HeaderContainer, HeaderNavLink, TextoElement, ContainerImg, HeaderLinkScroll } from './HeaderElement'

import { Link as LinkScroll, animateScroll as scroll } from "react-scroll";
import { useSelector, useDispatch } from 'react-redux'
import { MY_DATA_SLICE } from '../../../redux/slices/MyData/Interface_MyDataSlice';
import { TOKEN_SLICE } from '../../../redux/slices/tokenData/interface_TokenData'; 
import { error_MSG, question_MSG, success_MSG, TestDialog } from '../../../utilities/sweetAalerts/dialogs';

export const Header = ({ logeado = false }) => {

    // get from redux
    const dispatch = useDispatch();
    const { myData: getMyDataFromRedux } = useSelector(state => state.auth.myDataSlice);
    const { tokenDataObjet: getTokenFromRedux } = useSelector(state => state.auth.tokenDataSlice);


    return (
        <>
            {logeado ?

                // LOGUEADO
                <HeaderElement>
                    <HeaderContainer align="left">
                        <ContainerImg>
                            <img width="60" src={iconosisboa} alt='LOGO' />
                            <TextoElement cursor="pointer" color="white" hovercolor="#00A19D" font="30px" >
                                <HeaderNavLink color="white" hovercolor="#00A19D" to="/">SisBOA Pedidos </HeaderNavLink>
                            </TextoElement>
                            <TextoElement color="yellow" font="18px" align="right" cursor="pointer">
                                {getMyDataFromRedux.name}
                            </TextoElement>
                        </ContainerImg>
                    </HeaderContainer>


                    {/* <HeaderContainer align="right">
                        <TextoElement color="white" font="16px" align="right" cursor="pointer">
                            <HeaderNavLink color="white" hovercolor="#00A19D" to="/home">AYUDA </HeaderNavLink>
                        </TextoElement>
                        <TextoElement color="white" font="16px" align="right" cursor="pointer">
                            <HeaderLinkScroll
                                color="white"
                                hovercolor="#00A19D"
                                to="ourPlansContent">
                                INFORMACION
                            </HeaderLinkScroll>
                        </TextoElement>

                        <TextoElement color="white" font="16px" align="right" cursor="pointer">
                            <HeaderNavLink onClick={async () => {
                               await question_MSG('Cerrando Sesión', 'Desea cerrar sesión?') ?
                                    <>
                                        {await dispatch(MY_DATA_SLICE.SET_NEW({}))}
                                        {await dispatch(TOKEN_SLICE.SET_NEW({}))}
                                        {await logOut()}
                                    </>
                                    : <></>
                            }} color="white" hovercolor="#00A19D" to="/home">
                                LOG OUT
                            </HeaderNavLink>
                        </TextoElement>
                    </HeaderContainer> */}
                </HeaderElement>


                :








                // NO LOGUEADO  (NO ESTA EN USO MOMENTANEAMENTE)
                <HeaderElement >
                    <HeaderContainer align="left">
                        <ContainerImg>
                            <img width="60" src={iconosisboa} alt='LOGO' />
                            <TextoElement color="white" font="30px" cursor="pointer">
                                <HeaderNavLink color="white" hovercolor="#00A19D" to="/">SisBOA Pedidos </HeaderNavLink>
                            </TextoElement>
                        </ContainerImg>
                    </HeaderContainer>



              {/* <HeaderContainer align="right">
                        <TextoElement color="white" font="16px" align="right" cursor="pointer">
                            <HeaderLinkScroll
                                color="white"
                                hovercolor="#00A19D"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={120}
                                to="ourPlansContent">
                                INFORMACION
                            </HeaderLinkScroll>
                        </TextoElement>

                        <TextoElement color="white" font="16px" align="right" cursor="pointer">
                            <HeaderLinkScroll
                                color="white"
                                hovercolor="#00A19D"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={120}
                                to="registerContent">
                                REGISTRARSE
                            </HeaderLinkScroll>
                        </TextoElement>

                        <TextoElement color="white" font="16px" align="right" cursor="pointer">
                            <HeaderNavLink color="white" hovercolor="#00A19D" to="/login">LOGIN </HeaderNavLink>
                        </TextoElement>
                    </HeaderContainer> 
                */}

                
                </HeaderElement>

            }

        </>

    )
}
