import styled from "styled-components"


// FOOTER
export const FooterGrid = styled.footer`
background-color: #090910;
color: white;
grid-column-start: 1;
grid-column-end: -1;
padding: 150px;
`

export const Div = styled.div`

    text-align: center;
    /* margin-bottom: 130px; */
`


export const Texto = styled.span`   
    font-size: ${props=> props.font};

    cursor: pointer;
    :hover{
        color: #00A19D ;
    }
`

export const ImgFooter = styled.img`   
    width: ${props=> props.widthImg};
    cursor: pointer;

`
 