import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getMeWithToken, getTokenValidationAccount } from '../../../Api/peticiones_server'

import iconogogym from '../../../images/gogym/icono.png'
import successActivation from '../../../images/icons/img/success-activation.png'
import errorActivation from '../../../images/icons/img/error-activation.png'
import { MessageItem } from './MessageItem'
import { Texto, ContainerImg, ContainerElements } from './ValidatingPageElements';// styleds
import { Header } from '../Header/Header'

import { error_MSG } from   '../../../utilities/sweetAalerts/dialogs';

export const ValidatingAccount = () => {

    const { token_access: param_token_access } = useParams();
    const navitage = useNavigate()

    // REACT QUERRY 1
    const queryClient = useQueryClient()

    // Get-Token
    const { data: getToken, success: successGetToken, error: errorGetToken, isLoading: isLoadingGetToken } = useQuery('get_token_from_web', async () => {

        if (param_token_access) {
            const tokenOfActivation = {
                "token": param_token_access
            };
            return getTokenValidationAccount(tokenOfActivation);
        } else { throw new Error('EL TOKEN NO EXISTE') }


      // SOLO TEST
      /*
            if (true) {
                const tokenOfActivation = {
                    "token":  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvaWMtY29udGluZW50YWwuc2lzYm9hLm5ldFwvSUMtUGVkaWRvcy1CYWNrZW5kXC9wdWJsaWNcL2FwaVwvYXV0aFwvVXNlcnNcL2xvZ2luIiwiaWF0IjoxNjQ1ODkzNTA5LCJleHAiOjE2NDU4OTcxMDksIm5iZiI6MTY0NTg5MzUwOSwianRpIjoib0hZeWM5QXRMR1NabDJrTSIsInN1YiI6MiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.YDQ9vayxfmke1dujdp0Pakw5wbydbF8DP5-ITFVYihU"
                };
                return getTokenValidationAccount(tokenOfActivation);
            } else { throw new Error('EL TOKEN NO EXISTE') }
      */

    })

    // Get-Me
    const { data: myData, success: successMyData, error: errorMyData, isLoading: isLoadingMyData } = useQuery('get_Me_With_Token', async () => {
        if (param_token_access) {
            const tokenOfActivation = {
                "token": param_token_access
            };
            return getMeWithToken(tokenOfActivation);
        } else { throw new Error('EL TOKEN NO EXISTE') }
    })


    /*
        useEffect(() => {
            try {
                if (myData.payload.name) {
                    console.log(myData.payload.name);
                }
            } catch (error) {console.log('no se encontró los datos del usuario')}
        }, [myData])
    */


    // Mutations
    const postAndGetLoginToken = useMutation(getTokenValidationAccount, {
        onSuccess: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries('get_token_from_web')
        },
    })



    if (isLoadingGetToken) {
        console.log('CARGANDOO ...')

        return <div style={{ textAlign: 'center', marginTop: '10%' }}>
            <h3 style={{ marginRight: '120px' }}> Validando Usuario ...</h3>
            <div className="spinner-border text-info" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>

        // return <h1 className='btn-success'>Validando Usuario ...</h1>
    }
    if (errorGetToken) {

     
        // console.log('ERROR 5555 ...' + errorGetToken)
        error_MSG('No se ha encontrado un usuario con los datos suministrados');
    }








    return (

        <div>
            <Header logeado={true} />

            <ContainerElements>
                <Texto color="black" letterFont="40px">
                    ACTIVACIÓN DE USUARIO
                </Texto>
            </ContainerElements>




            {


                // SUCCESS
                getToken.success ?

                    <MessageItem
                        imgLink={successActivation}
                        imgAltMessage="SUCCESS-ACTIVATION"
                        buttonTitle="Aceptar"
                        messageTitle={getToken.msg}
                        finalMsgInfo="CUENTA ACTIVADA!" />
                    :
                    //ERROR
                    !getToken.success ?
                        <MessageItem
                            imgLink={errorActivation}
                            imgAltMessage="ERROR ACTIVATING ACCOUNT"
                            buttonTitle="Registrarme"
                            messageTitle={getToken.msg}
                            finalMsgInfo="REGISTRANDO ACCOUNT!" />
                        :
                        //NO EXISTE TOKEN
                        navitage('/login')
            }

        </div>


    )
}
