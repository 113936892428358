import styled from "styled-components";





export const Container = styled.div`
    max-width: 768px;
    max-height: 700px;
    border-radius: 10px;
    justify-content: center;
    text-align: center;
    margin: ${props=> props.margin};
    margin-top: 50px;

`


export const LoginContainer = styled.div`
    /* border-radius: 6px; */
    min-height: 400px;
    padding: 20px;
    background: #fff;
    justify-content: center;
    text-align: left;
    margin: 0px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,161,157,0); 
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,161,157,0);



    @media screen and (max-width: 768px){
        grid-template-columns: repeat(1, 1fr);
    }

    @media screen and (max-width: 480px){
        grid-template-columns: repeat(1, 1fr);
    }
`

// COMMUN USAGES

export const Texto = styled.span`
    font-size: ${props => props.font};
    color: ${props => props.color};
    font-family: 'Roboto', sans-serif;
    font-family: 'Roboto Condensed', sans-serif;
    

`

export const ImgContainer = styled.img`
    max-width: 300px;
    max-height: 300px;
    margin-right: 20px;

@media screen and (max-width: 768px){
    max-width: 200px;
    margin-top: 20px;
    margin: auto;
    margin-top: 20px;
    }

@media screen and (max-width: 480px){
    max-width: 200px;
    margin-top: 20px;
    margin: auto;
    margin-top: 20px;
}
`

export const Button = styled.button`
border-radius: 16px;
padding: 5px 40px 5px 40px ;
background-color: #00A19D;
color: white;

:hover{
background-color: #0c8886;
}
`