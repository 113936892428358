import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { Link as LinkScroll, animateScroll as scroll } from "react-scroll";



export const HeaderElement = styled.div`
    padding: 30px;
    /* background-color: #041C32; */
    background-color: #090910;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
`

export const HeaderContainer = styled.div`
   text-align: ${props=> props.align};
`


export const HeaderNavLink = styled(NavLink)`
    color: ${props => props.color};
    :hover{
        color: ${props => props.hovercolor};
    }
`


export const HeaderLinkScroll= styled(LinkScroll)`
    color: ${props => props.color};
    :hover{
        color: ${props => props.hovercolor};
    }
`





export const TextoElement = styled.span`
    font-size: ${props => props.font};
    color: ${props => props.color};
    cursor: ${props=> props.cursor};
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    text-align: ${props=> props.align};
    padding: 10px;
`





export const ContainerImg = styled.div`
/* float: left; */
margin-right: 20px;
`