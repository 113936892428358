

import  myDataSlice  from '../slices/MyData/myData';
import tokenDataSlice  from '../slices/tokenData/tokenData';
import { combineReducers, createStore } from 'redux';

// using redux-persist
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web


const persistConfig = { 
    key: 'root',
    storage,
};


// PRIVADO
const authReducers = combineReducers({
    tokenDataSlice: tokenDataSlice,
    myDataSlice: myDataSlice
});

const allReducers = combineReducers({
    auth: persistReducer(persistConfig, authReducers),
    //nameRecucerPublico: nameRecucerPublico,
  })

let store = createStore(allReducers)
let persistor = persistStore(store)
export { store, persistor }
