import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Header } from '../Header/Header';
import { Container, LoginContainer, Texto, ImgContainer, Button } from './LoginElement';
import userRegister from '../../../images/commun-imges/user-register.svg'

import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { FormikInputsControl } from '../../../utilities/FormikInputsControl';
import { error_MSG, question_MSG, success_MSG, TestDialog } from '../../../utilities/sweetAalerts/dialogs';
import { createNewUser } from '../../../Api/peticiones_server';


export const Register = () => {
    const navigate = useNavigate();


    // test guardar tooken in storage
    const setDemoTokenStorage = async (e) => {


        let confirmarSesion = await window.confirm('Desea Iniciar sesion?')


        // SIMULACRO TRAYENDO DATA FROM API-REST
        if (confirmarSesion) {

            localStorage.removeItem('AuthUserToken');
            const AuthUserToken = [{
                name: 'Braulio 0002',
                token: '12345kg77',
                logged: true
            }]

            localStorage.setItem('AuthUserToken', JSON.stringify(AuthUserToken));
            navigate('/home')
            console.log('welcome');

        } else {
            e.preventDefault();
            console.log('cancelaste');
        }
    }




    const CreateNewUser = async (data) => {

        /*
        const confirmacion = await question_MSG('Iniciando Sesion', 'Iniciar ahora?');
        if (confirmacion) {
            success_MSG('SUCCESS!');
        } else {
            error_MSG('dijiste no!');
        }
       */
        let userData = {   
            name: data.name,
            email: data.email,
            password: data.password,
            objetivoMeta: "0",
            pesoActual: "0",
            porcentajeIMC: "0",
            phone_number: "0",
            celular_number: "0"
        };



        if (data) {
            createNewUser(userData).then(data => {
                try {
                    // ESTADO
                    console.log(data);
                    data.success ? success_MSG("Regístro Exitoso!") : error_MSG("Ya existe un usuario con este correo", 3000)

                } catch (error) { }

            })
        }
    }







    // Yup validate form:
    const validateYupForm = Yup.object({

        name:
            Yup.string()
                .max(50, 'Excede el maximo de caracteres permitido')
                .min(3, 'Debe tener al menos 3 caracteres')
                .required('Este campo es requerido'),
        email:
            Yup.string()
                .max(100, 'Excede el maximo de caracteres permitido')
                .email('Debe ser un correo valido')
                .required('Este campo es requerido'),
        password:
            Yup.string()
                .min(4, 'Debe tener al menos 4 caracteres')
                .required('Este campo es requerido'),

        passwordConfirm:
            Yup.string()
                .test('Good', 'Las contraseñas deben coincidir', function (value) {
                    return this.parent.password === value
                })

    })


    return (
        <>
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    password: '',
                    passwordConfirm: '',
                }}

                validationSchema={validateYupForm}

                //** FINALIZAR Y ENVIAR
                onSubmit={async (userData) => {

                    CreateNewUser(userData);

                }}
            >


                {formik => (
                    <div>
                        <Form>
                            {/* <Header logeado={false} /> */}

                            <Container>
                                {/* <Texto font="30px" color="#00A19D"> Nuevo Registro</Texto> */}

                                <LoginContainer>
                                    <ImgContainer src={userRegister} />
                                    <div>
                                        <Texto font="25px" color="#00A19D"> COLOCA TUS DATOS </Texto>
                                        <br /><br />

                                        <FormikInputsControl control='input' label='Nombre' name='name' />
                                        <FormikInputsControl control='input' label='Correo' name='email' />
                                        <FormikInputsControl control='input' type="password" label='Contraseña' name='password' />
                                        <FormikInputsControl control='input' type="password" label='Confirmar Contraseña' name='passwordConfirm' />

                                        <br />
                                        <Button type="submit" color='#e50914'>Enviar</Button>
                                    </div>


                                </LoginContainer>



                                {/* <Texto font="20px" color="#00A19D"> GoGym  </Texto> <br/>
                                <Texto font="16px" color="white"> Push Yours Limits!  </Texto> */}
                            </Container>
                        </Form>
                    </div>
                )}
            </Formik>
        </>
    )
}
