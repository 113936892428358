import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { TokenLocalStorage } from '../../../Api/TokenLocalStorage';
import { Home } from '../../pages/Home/Home';
import { Login } from '../../pages/Login/Login';

//getting from redux
import { useSelector, useDispatch } from 'react-redux';


export const PrivateRouter =      ({ children }) =>    {

    const navegate = useNavigate();
    const { tokenDataObjet: getTokenFromRedux } = useSelector(state => state.auth.tokenDataSlice);

    
    return (
        getTokenFromRedux.logged ?
            <>
                {/* user is loged */}
                {children}
            </>
            :
            <>
                {/* user is  not loged */}
                <Home />
            </>
    )
}
