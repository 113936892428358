import React from 'react'

export const MessageItem = ({ imgLink, imgAltMessage, buttonTitle, messageTitle, finalMsgInfo }) => {

    return (
        <div>
            {
                // MESSAGE
                messageTitle ?
                    <div className='ValidationAccount'>
                        <div className="card Centered " >
                            <div className="card-body">
                                <img width="80" src={imgLink} alt={imgAltMessage} /> <br /><br />
                                <h5 className="card-title">{messageTitle} :)</h5>
                                <button onClick={() => console.log({ finalMsgInfo })} style={{ color: "white" }} className="btn btn-info ">
                                    {buttonTitle}
                                </button>
                            </div>
                        </div>
                    </div>
                    :
                    //NADA SUPLIDO
                    <></>
            }
        </div>
    )
}
