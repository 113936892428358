import React, { useEffect, useState } from 'react'
import { IndexPage } from '../../IndexPage/IndexPage';

//getting from redux
import { useSelector, useDispatch } from 'react-redux'
import { Home } from '../../pages/Home/Home';


export const PublicRutes = ({ children }) => {

    const { tokenDataObjet: getTokenFromRedux } = useSelector(state => state.auth.tokenDataSlice);

    return (getTokenFromRedux.logged ?
        <>
            {/* user is loged */}
            <Home />
        </>
        :
        <>
            {/* user is not loged */}
            {children}
        </>
    )
}
