import React from 'react'
import { Container, FooterGrid } from './FooterElement'
import { Link as LinkScroll, animateScroll as scroll } from "react-scroll";
import imgGoUp from '../../../images/icons/img/go-up.png'
import iconogogym from '../../../images/gogym/icono.png'


import { Texto, ImgFooter, Div } from './FooterElement';
export const Footer = () => {
    return (
        <FooterGrid>


            <Div>
                <ImgFooter
                    widthImg="140px"
                    src={iconogogym} />
                <br />
                <Texto font="18px" >Push yours Limits  </Texto>
            </Div>

            <ImgFooter
                width={80}
                spy={true}
                smooth={true}
                offset={-70}
                duration={0}
                src={imgGoUp}
                onClick={scroll.scrollToTop} />




            <Div>
                <Texto
                    font="14px"
                    align="center" >Mollit quis aliqua laboris sint non velit.Mollit quis aliqua laboris sint non velit.
                </Texto>
                <br/>
                <Texto
                    font="14px"
                    align="center" >sint non velit.Mollit quis aliqua laboris sint non velit.</Texto>
            </Div>


        </FooterGrid>
    )
}
