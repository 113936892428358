import styled from "styled-components";

// CONTAINER

export const PrincipalContainer = styled.div`
width: 100%;
height: 100vh;
`








export const RegisterContainer = styled.div`
    padding: 50px;
    width: 100%;
    /* min-height: 600px; */
    display: grid;
    grid-template-columns: 2fr 2fr;

    grid-template-areas: 
    "titleOurPlans titleOurPlans  "
    "titleOurPlans titleOurPlans  "
    
    ;  
`




export const OurPlansContainer = styled.div`
 display: grid;
 grid-template-columns: repeat(3, 1fr);
 grid-gap: 10px;
 padding: 10px;
grid-template-areas: 
"titleOurPlans titleOurPlans titleOurPlans"
"cardsPlans cardsPlans cardsPlans";  



/*Escritorio*/
@media (min-width: 1199.98px)  {
    grid-template-columns: repeat(3, 1fr);

    grid-template-areas: 
"titleOurPlans titleOurPlans titleOurPlans"
"cardsPlans cardsPlans cardsPlans";  
}
/*Fin Escritorio*/

/*Tablet*/
/*Portrait*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation:portrait) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 
"titleOurPlans titleOurPlans "
"cardsPlans cardsPlans ";  
}

/*landscape*/
@media (min0-width: 991.98px) and (max-width: 1199.98px)and (orientation:landscape) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 
"titleOurPlans titleOurPlans "
"cardsPlans cardsPlans ";  
}
/*fin tablet*/

/*movil*/
@media (max-width: 575.98px)and (orientation:portrait) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: 
"titleOurPlans  "
"cardsPlans  ";  
 }
/*landscape*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation:landscape) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 
"titleOurPlans titleOurPlans "
"cardsPlans cardsPlans ";  
}
/*fin movil*/
     background-color: #212529;
    

    /* grid-template-areas: 
    "titleOurPlans titleOurPlans titleOurPlans titleOurPlans"
    "cardsPlans cardsPlans cardsPlans cardsPlans";  */

    /* grid-template-areas: 
    "titleOurPlans titleOurPlans titleOurPlans titleOurPlans"
    "cardsPlans cardsPlans cardsPlans cardsPlans"; */
`





export const Div = styled.div`
color: ${props => props.color};
background-color: ${props => props.fondo};
border-radius: ${props => props.borderRadius};
margin-top:   ${props => props.marginTop};
margin-left:   ${props => props.marginLeft};


font-family: 'Roboto', sans-serif;
font-family: 'Roboto Condensed', sans-serif;
font-size: ${props => props.font};
margin-bottom: 10px;

`




export const CardPlans = styled.div`
    grid-area: cardsPlans;
    min-width: 250px;
    min-height: 250px;
    background-color: #fff;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;

`


// COMMUN USAGE

export const TileText = styled.span`
font-size: ${props => props.font};
color: ${props => props.color};
margin-top: ${props => props.marginTop};

font-family: 'Roboto', sans-serif;
font-family: 'Roboto Condensed', sans-serif;
display: block;
text-align: center;


grid-area: titleOurPlans;
`


export const Texto = styled.span`
font-size: ${props => props.font};
color: ${props => props.color};
font-family: 'Roboto', sans-serif;
font-family: 'Roboto Condensed', sans-serif;
`

