import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tokenDataObjet: {
        name: '.',
        token: '.',
        logged: false
    }
}

const tokenDataSlice = createSlice({
    name: 'tokenDataSlice',
    initialState,
    reducers: {
        setTokenSlice: (state, action) => {
            state.tokenDataObjet = action.payload
        },
        getTokenSlice: (state, action) => {
            state.tokenDataObjet = action.payload
        },
    }
})

export const {setTokenSlice, getTokenSlice} = tokenDataSlice.actions;
export default tokenDataSlice.reducer;
